<template>
  <div>
    <v-card class="segmented" flat>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <h5>{{ $t('workflows.titleStates') }}</h5>
          </v-col>
        </v-row>
      </v-card-title>
      <v-container>
        <v-radio-group
          v-model="initialStatus"
          v-for="(status, index) in value"
          :key="index"
          :label="$t('workflows.initialState')"
        >
          <v-container>
            <workflow-states-table-item
              v-model="value[index]"
              @statusIdModified="statusIdModified($event)"
              @deleteStatus="deleteStatus($event)"
            />
            <workflow-transition-table v-model="value[index]" />
          </v-container>
          <v-container>
            <workflow-feedback-status-table
              v-model="value[index]"
              :feedbackTypes="feedbackTypes"
            />
          </v-container>
        </v-radio-group>
        <v-btn elevation="2" large @click="createStatus">{{
          $t('workflows.statusCreate')
        }}</v-btn>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { feedbackEnums } from '@/enums/feedbacks.js';
import workflowStatesTableItem from '@/components/workflows/workflow-states-table-item.vue';
import workflowTransitionTable from '@/components/workflows/workflow-transition-table.vue';
import workflowFeedbackStatusTable from '@/components/workflows/workflow-feedback-status-table.vue';
export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    feedbackTypes: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  mounted() {
    this.initialStatus = this.value.find((x) => x.isInitialStatus);
  },

  watch: {
    value: {
      handler(newValue) {
        this.initialStatus = newValue.find((x) => x.isInitialStatus);
      },
      deep: true
    },
    initialStatus(newValue) {
      this.value.forEach((x) => (x.isInitialStatus = x === newValue));
    }
  },

  methods: {
    createStatus() {
      const newStatus = {
        statusId: '0',
        isInitialStatus: false,
        translations: [{ languageKey: 'de-DE', translation: 'Neuer Status' }],
        descriptions: [
          { languageKey: 'de-DE', translation: 'Ein neuer Status' }
        ],
        statusChanges: [],
        feedbackStatus: Object.values(feedbackEnums.feedbackTypes).map((x) => ({
          feedbackType: x.toString(),
          feedbackStatusId: feedbackEnums.feedbackStatus.OPEN.toString()
        }))
      };
      this.value.push(newStatus);
    },

    deleteStatus(statusId) {
      this.value.splice(
        this.value.findIndex((x) => x.statusId === statusId),
        1
      );
    },

    statusIdModified(modification) {
      this.$emit('statusIdModified', modification);
    }
  },

  data() {
    return {
      initialStatus: null
    };
  },

  components: {
    workflowStatesTableItem,
    workflowTransitionTable,
    workflowFeedbackStatusTable
  }
};
</script>
