<template>
  <v-select
    v-model="choiceInSelectedLanguage"
    :items="optionsInSelectedLanguage"
    :label="label"
    dense
  >
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    currentLanguageKey: {
      type: String,
      required: false,
      default: () => 'de-DE'
    }
  },

  mounted() {
    this.updateChoice();
  },

  watch: {
    value: {
      handler() {
        this.updateChoice();
      },
      deep: true
    },
    choiceInSelectedLanguage(newValue) {
      this.$emit('change', newValue);
    }
  },

  computed: {
    optionsInSelectedLanguage() {
      return this.items.map((x) => {
        const optionTranslation = x.translations.find(
          (y) => y.languageKey === this.currentLanguageKey
        );
        return optionTranslation
          ? optionTranslation.translation
          : this.$t('noTranslationAvailable');
      });
    }
  },

  methods: {
    updateChoice() {
      const choiceTranslation = this.value.find(
        (x) => x.languageKey === this.currentLanguageKey
      );
      this.choiceInSelectedLanguage = choiceTranslation
        ? choiceTranslation.translation
        : '';
    }
  },

  data() {
    return {
      choiceInSelectedLanguage: ''
    };
  }
};
</script>
