<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h4>
            {{ $t(`feedbacks.donorRoles.${value.donorRole}`) }}
          </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <workflow-access-table-status
            v-for="(access, index) in value.feedbackTypeAccess"
            v-model="value.feedbackTypeAccess[index]"
            @deleteAccess="deleteAccess($event)"
            :key="index"
          />
          <v-btn elevation="2" large @click="createAccess">{{
            $t('workflows.accessCreate')
          }}</v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import workflowAccessTableStatus from '@/components/workflows/workflow-access-table-status.vue';
export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  methods: {
    deleteAccess(entry) {
      this.value.feedbackTypeAccess = this.value.feedbackTypeAccess.filter(
        (x) => x !== entry
      );
    },

    createAccess() {
      const newAccess = {
        feedbackType: '1',
        requiredStatus: '>=100',
        access: 'r'
      };
      this.value.feedbackTypeAccess.push(newAccess);
    }
  },

  components: {
    workflowAccessTableStatus
  }
};
</script>
