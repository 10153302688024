<template>
  <div>
    <v-card class="segmented" flat>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <h5>{{ $t('workflows.titleUserAccess') }}</h5>
          </v-col>
        </v-row>
      </v-card-title>
      <v-container>
        <template v-for="(donorPermissions, index) in value">
          <workflow-access-table-item v-model="value[index]" :key="index" />
        </template>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import workflowAccessTableItem from '@/components/workflows/workflow-access-table-item.vue';
export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  components: {
    workflowAccessTableItem
  }
};
</script>
