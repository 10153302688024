<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ $t('workflows.titleFeedbackStatus') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-for="(status, index) in value.feedbackStatus">
            <workflow-feedback-status-table-item
              v-if="feedbackTypes.includes(status.feedbackType)"
              v-model="value.feedbackStatus[index]"
              :key="index"
            />
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import workflowFeedbackStatusTableItem from '@/components/workflows/workflow-feedback-status-table-item.vue';

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    },

    feedbackTypes: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  components: {
    workflowFeedbackStatusTableItem
  }
};
</script>
