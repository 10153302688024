<template>
  <div>
    <v-row dense>
      <v-col cols="1">
        <v-radio v-model="value"></v-radio>
      </v-col>
      <v-col cols="1">
        <v-text-field
          v-model="statusId"
          :label="$t('workflows.statusId')"
          dense
        />
      </v-col>
      <v-col>
        <text-localization-field
          v-model="value.translations"
          :label="$t('workflows.statusName')"
          :currentLanguageKey="languageKey"
        />
      </v-col>
      <v-col cols="2" class="d-flex justify-end">
        <v-switch
          v-model="value.isFinalStatus"
          :label="$t('workflows.finalStatus')"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <text-localization-field
          v-model="value.descriptions"
          :label="$t('workflows.statusDescription')"
          :currentLanguageKey="languageKey"
        />
      </v-col>
      <v-btn elevation="2" large @click="deleteStatus">{{
        $t('delete')
      }}</v-btn>
    </v-row>
  </div>
</template>

<script>
import textLocalizationField from '@/components/misc/localization-text-field.vue';
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters({
      languageKey: 'languageKey'
    })
  },

  data() {
    return {
      statusId: this.value.statusId
    };
  },

  watch: {
    statusId(newValue, oldValue) {
      this.value.statusId = newValue;
      this.$emit('statusIdModified', {
        oldId: oldValue,
        newId: newValue
      });
    },

    value: {
      handler(newValue) {
        this.statusId = newValue.statusId;
      },
      deep: true
    }
  },

  methods: {
    deleteStatus() {
      this.$emit('deleteStatus', this.value.statusId);
    }
  },

  components: {
    textLocalizationField
  }
};
</script>
