<template>
  <div>
    <v-row>
      <v-col>
        <localization-select
          v-model="statusResultTranslations"
          :items="statusList"
          :label="$t('workflows.transitionTo')"
          :currentLanguageKey="languageKey"
          @change="changeDestinationStatus($event)"
          dense
        />
      </v-col>
      <v-col cols="5">
        <v-select
          v-model="value.feedbackType"
          :items="transitionTypeTranslations"
          item-value="value"
          item-text="label"
          :label="$t('workflows.transitionFeedbackType')"
          dense
        />
      </v-col>
      <v-btn elevation="2" large @click="deleteTransition">{{
        $t('delete')
      }}</v-btn>
    </v-row>
  </div>
</template>

<script>
import localizationSelect from '@/components/misc/localization-select.vue';
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  mounted() {
    const status = this.statusList.find(
      (x) => x.statusId === this.value.statusResult
    );
    this.statusResultTranslations = status ? status.translations : [];
  },

  computed: {
    ...mapGetters({
      statusList: 'workflows/getStatusList',
      feedbackTypes: 'workflows/getFeedbackTypes',
      languageKey: 'languageKey'
    }),

    transitionTypeTranslations() {
      return this.feedbackTypes.map((x) => ({
        value: x,
        label: this.$t(`workflows.transitionTypes.${x}`)
      }));
    }
  },

  methods: {
    deleteTransition() {
      this.$emit('deleteTransition', this.value);
    },

    changeDestinationStatus(selection) {
      this.value.statusResult = this.statusList.find(
        (x) =>
          x.translations.find((y) => y.languageKey === this.languageKey)
            .translation === selection
      ).statusId;
    }
  },

  data() {
    return {
      statusResultTranslations: []
    };
  },

  components: {
    localizationSelect
  }
};
</script>
