<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          v-model="feedbackTypeTranslation"
          :label="feedbackLabel"
          dense
          readonly
        />
      </v-col>
      <v-col>
        <v-select
          v-model="value.feedbackStatusId"
          :items="feedbackStatusTranslations"
          item-value="value"
          item-text="label"
          :label="$t('workflows.status')"
          dense
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { feedbackEnums } from '@/enums/feedbacks.js';
export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  computed: {
    feedbackTypeTranslation() {
      return this.$t(`feedbacks.typeOptions.${this.value.feedbackType}`);
    },

    feedbackStatusTranslations() {
      return Object.values(feedbackEnums.feedbackStatus).map((x) => ({
        value: x.toString(),
        label: this.$t(`feedbacks.statusOptions.${x}`)
      }));
    },

    feedbackLabel() {
      return (
        this.$t('workflows.feedback') + '-' + this.$t('feedbacks.type_short')
      );
    }
  }
};
</script>
