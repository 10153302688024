<template>
  <div>
    <v-row dense>
      <v-col cols="2">
        <v-select
          v-model="accessOperator"
          :items="operators"
          :label="$t('workflows.accessOperators')"
          dense
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="accessStatus"
          :label="$t('workflows.statusId')"
          dense
          readonly
        />
      </v-col>
      <v-col>
        <localization-select
          v-model="accessStatusTranslations"
          :items="statusList"
          :label="$t('workflows.requiredStatus')"
          :currentLanguageKey="languageKey"
          @change="updateStatus($event)"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="value.feedbackType"
          :items="feedbackTypesTranslations"
          item-text="label"
          item-value="value"
          :label="$t('workflows.allowedFeedbackType')"
          dense
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <v-checkbox
          v-model="readPermission"
          :label="$t('workflows.read')"
          dense
        />
      </v-col>
      <v-col cols="2">
        <v-checkbox
          v-model="writePermission"
          :label="$t('workflows.write')"
          dense
        />
      </v-col>
      <v-col cols="2">
        <v-checkbox
          v-model="approvePermission"
          :label="$t('workflows.approve')"
          dense
        />
      </v-col>
      <v-col cols="2">
        <v-checkbox
          v-model="updatePermission"
          :label="$t('workflows.update')"
          dense
        />
      </v-col>
      <v-col cols="2">
        <v-checkbox
          v-model="commentPermission"
          :label="$t('workflows.comment')"
          dense
        />
      </v-col>
      <v-col cols="2">
        <v-checkbox
          v-model="vetoPermission"
          :label="$t('feedbacks.veto')"
          dense
        />
      </v-col>
      <v-col cols="2">
        <v-checkbox
          v-model="compareOnlyPermission"
          :label="$t('workflows.compareOnly')"
          dense
        />
      </v-col>
    </v-row>
    <v-row dense class="mb-4">
      <v-col cols="3">
        <v-btn elevation="2" small @click="deleteAccess" dense>{{
          $t('delete')
        }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import localizationSelect from '@/components/misc/localization-select.vue';
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  mounted() {
    const requiredStatus = this.value.requiredStatus;
    this.accessOperator = requiredStatus.substr(
      0,
      requiredStatus.indexOf('=') + 1
    );
    this.accessStatus = requiredStatus.substr(requiredStatus.indexOf('=') + 1);
    const status = this.statusList.find(
      (x) => x.statusId === this.accessStatus
    );
    this.accessStatusTranslations = status ? status.translations : [];
    this.permissions = this.value.access.split('+');
    this.readPermission = this.permissions.includes('r');
    this.writePermission = this.permissions.includes('w');
    this.approvePermission = this.permissions.includes('a');
    this.updatePermission = this.permissions.includes('u');
    this.commentPermission = this.permissions.includes('c');
    this.vetoPermission = this.permissions.includes('v');
    this.compareOnlyPermission = this.permissions.includes('co');
  },

  watch: {
    value: {
      handler() {
        this.accessStatus = this.value.requiredStatus.substr(
          this.value.requiredStatus.indexOf('=') + 1
        );
      },
      deep: true
    },
    readPermission(newValue) {
      this.updatePermissions('r', newValue);
    },
    writePermission(newValue) {
      this.updatePermissions('w', newValue);
    },
    approvePermission(newValue) {
      this.updatePermissions('a', newValue);
    },
    updatePermission(newValue) {
      this.updatePermissions('u', newValue);
    },
    commentPermission(newValue) {
      this.updatePermissions('c', newValue);
    },
    vetoPermission(newValue) {
      this.updatePermissions('v', newValue);
    },
    accessOperator(newValue) {
      this.value.requiredStatus = newValue + this.accessStatus;
    },
    compareOnlyPermission(newValue) {
      this.updatePermissions('co', newValue);
    }
  },

  computed: {
    ...mapGetters({
      feedbackTypes: 'workflows/getFeedbackTypes',
      statusList: 'workflows/getStatusList',
      languageKey: 'languageKey'
    }),
    feedbackTypesTranslations() {
      return this.feedbackTypes.map((x) => ({
        value: x,
        label: this.$t(`feedbacks.typeOptions.${x}`)
      }));
    }
  },

  methods: {
    updateStatus(selectedStatus) {
      this.accessStatus = this.statusList.find(
        (x) =>
          x.translations.find((y) => y.languageKey === this.languageKey)
            .translation === selectedStatus
      ).statusId;
      this.value.requiredStatus = this.accessOperator + this.accessStatus;
    },
    updatePermissions(perm, add) {
      if (add) {
        if (!this.permissions.includes(perm)) {
          this.permissions = [...this.permissions, perm];
        }
      } else {
        this.permissions = this.permissions.filter((x) => x !== perm);
      }
      this.value.access = this.permissions.join('+');
    },
    deleteAccess() {
      this.$emit('deleteAccess', this.value);
    }
  },

  data() {
    return {
      operators: ['=', '<=', '>='],
      accessOperator: '',
      accessStatus: '',
      accessStatusTranslations: [],
      permissions: [],
      readPermission: false,
      writePermission: false,
      approvePermission: false,
      updatePermission: false,
      vetoPermission: false,
      commentPermission: false,
      compareOnlyPermission: false
    };
  },

  components: {
    localizationSelect
  }
};
</script>
