<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ $t('workflows.titleTransitions') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-for="(toStatus, index) in value.statusChanges">
            <workflow-transition-table-item
              v-model="value.statusChanges[index]"
              :key="index"
              @deleteTransition="deleteTransition($event)"
            />
          </template>
          <v-btn elevation="2" large @click="createTransition">{{
            $t('workflows.transitionCreate')
          }}</v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import workflowTransitionTableItem from '@/components/workflows/workflow-transition-table-item.vue';

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  methods: {
    deleteTransition(transition) {
      this.value.statusChanges = this.value.statusChanges.filter(
        (x) => x !== transition
      );
    },
    createTransition() {
      this.value.statusChanges.push({
        statusResult: this.value.statusId,
        feedbackType: '1'
      });
    }
  },

  components: {
    workflowTransitionTableItem
  }
};
</script>
